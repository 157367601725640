import * as React from "react"
import { SEO } from "../components/seo"
import Layout from '../components/layout';
import ScrollAnimation from 'react-animate-on-scroll';
import { StaticImage } from "gatsby-plugin-image"

const Endorsements = (props) => {
  return (
    <Layout pathname={props.location.pathname}>
      <section className="h-72 bg-gradient-to-tl from-primary-dark to-primary-light relative after:content-[''] after:block after:bg-mask after:bg-['100%'] after:bg-bottom after:bg-no-repeat after:absolute after:inset-x-0 after:-bottom-1 after:w-full after:h-36 after:z-10">
        <div className="absolute w-full text-center inset-x-0 top-1/2 -translate-y-1/2 z-10 lg:p-20">
          <h1 className="animate__animated animate__fadeInDown animate__slower text-4xl lg:text-5xl text-white mb-4">Endorsements</h1>
        </div>
      </section>
      <section className="my-14 text-gray-700 ">
        <div className="container mx-auto text-center">
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><h2 className="font-bold text-xl mb-4 text-primary">Federal Leaders</h2></ScrollAnimation>
              <p className="text-base leading-loose">
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Rep. Lou Correa</strong> (D-CA)<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Rep. Katie Porter</strong> (D-CA)<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Rep. Linda Sanchez</strong> (D-CA)<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Rep. Jill Tokuda</strong> (D-HI)<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Rep. Sara Jacobs</strong> (D-CA)<br /></ScrollAnimation>
            </p>
        </div>
      </section>
      <section className="mx-auto py-20 px-4 text-gray-700 text-center bg-slate-100">
        <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><h2 className="font-bold text-xl mb-4 text-primary">Organizations</h2></ScrollAnimation>

        <div className="container mx-auto text-center">
            <p className="text-base leading-loose">
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><StaticImage src="../images/ca-dem-endorsement.png" alt="California Democratic Party Endorsed" placeholder="blurred" layout="constrained" className="mb-5" width={400} /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>ASPIRE PAC</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>BOLD PAC</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>PODER PAC</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Orange County Labor Federation (OCLF)</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Los Angeles/Orange County Building & Construction Trades Council</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>International Brotherhood of Teamsters Joint Council 42</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>International Union of Painters & Allied Trades District Council 36</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Orange County Employees Association (OCEA)</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>National Union of Healthcare Workers (NUHW)</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>United Association of Refrigeration Fitters, Steamfitters, and Welders Local 250</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Hubert H. Humphrey Democratic Club</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>United Food & Commercial Workers Local 324</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>National Women's Political Caucus (NWPC)</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Service Employees International Union (SEIU) California</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Women for American Values and Ethics (WAVE)</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>California Teachers Association (CTA)</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Laborers' International Union North America (LIUNA)</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>National Education Association (NEA)</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Western States Regional Council of Carpenters</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>United Steelworkers Local 675</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>American Federation of Government Employees, Local 675</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Unite Here Local 11</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>CSUF Democrats</strong><br /></ScrollAnimation>
            </p>
        </div>

      </section>
      <section className="text-gray-700 py-20">
        <div className="container mx-auto text-center">
            <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><h2 className="font-bold text-xl mb-4 text-primary">State and County Leaders</h2></ScrollAnimation>
            <p className="text-base leading-loose">
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Monique Limon</strong>, State Senator, 19th District<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Tom Umberg</strong>, State Senator, 34th District<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Sharon Quirk-Silva</strong>, Assemblywoman, 67th District<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Cottie Petrie-Norris</strong>, Assemblywoman 73rd District<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Josh Lowenthal</strong>, Assemblyman, 69th District<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Tom Daly</strong>, Assemblyman 69th District (ret.)<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Katrina Foley</strong>, Orange County Supervisor, 5th District<br /></ScrollAnimation>
            </p>
        </div>
      </section>
      <section className="my-8 px-10 lg:px-36 text-gray-700 text-center relative before:content-[''] bg-slate-100 py-20">
      <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><h2 className="font-bold text-xl mb-4 text-primary">City Leaders</h2></ScrollAnimation>
        <div className="container mt-10 mx-auto text-center">
            <p className="text-base leading-loose">
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Natalie Rubalcava</strong>, Anaheim City Councilmember<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Tiffany Ackley</strong>, Aliso Viejo City Councilmember<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Richard Hurt</strong>, Aliso Viejo City Councilmember<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Susan Sonne</strong>, Buena Park Mayor Pro Tem<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Connor Traut</strong>, Buena Park City Councilmember<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Jose Trinidad Castaneda</strong>, Buena Park City Councilmember<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Frank Aurelio Yokoyama</strong>, Cerritos City Councilmember<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Mark Pulido</strong>, Cerritos Mayor (ret.)<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Gloria Pulido</strong>, Cerritos Planning Commissioner<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Juan Garza</strong>, Central Basin Municipal Water District Director<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>John Stephens</strong>, Costa Mesa Mayor<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Arlis Reynolds</strong>, Costa Mesa City Councilmember<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Andrea Marr</strong>, Costa Mesa City Councilmember<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Manuel Chavez</strong>, Costa Mesa City Councilmember<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Jocelyn Yow</strong>, Eastvale City Councilmember<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Farrah Khan</strong>, Irvine Mayor<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Diedre Thu-Ha Nguyen</strong>, Garden Grove City Councilmember (ret.)<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>John O'Neill</strong>, Garden Grove City Councilmember<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Luis Roa</strong>, Hawaiian Gardens Mayor<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Dan Kalmick</strong>, Huntington Beach City Councilmember<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Glenn Grandis</strong>, Fountain Valley City Councilmember<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Rhonda Bolton</strong>, Huntington Beach City Councilmember<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Natalie Moser</strong>, Huntington Beach City Councilmember<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Kim Carr</strong>, Huntington Beach City Councilmember (ret.)<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Alex Rounaghi</strong>, Laguna Beach City Councilmember<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Janet Conklin</strong>, La Palma City Councilmember<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Marshall Goodman</strong>, La Palma City Councilmember<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Anthony Phan</strong>, Milpitas City Councilmember<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Ellen Kamei</strong>, Mountain View City Councilmember<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Rachel Hernandez</strong>, Riverbank City Councilmember<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Anthony Rocha</strong>, Salinas City Councilmember<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Joe Kalmick</strong>, Seal Beach City Councilmember<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Valerie Amezcua</strong>, Santa Ana Mayor<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Phil Bacerra</strong>, Santa Ana City Councilmember<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>David Penaloza</strong>, Santa Ana City Councilmember<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Thai Viet Phan</strong>, Santa Ana City Councilmember<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Hong Alyce Van</strong>, Stanton City Councilmember<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Letitia Clark</strong>, Tustin City Councilmember<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Beckie Gomez</strong>, Tustin City Councilmember<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Jimmy Pham</strong>, Westminster Traffic Commissioner<br /></ScrollAnimation>
            </p>
        </div>
      </section>
      <section className="mx-auto my-8 py-20 px-4 lg:px-36 text-gray-700 text-center">
        <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><h2 className="font-bold text-xl mb-4 text-primary">Education Leaders</h2></ScrollAnimation>

        <div className="container mt-10 mx-auto text-center">
            <p className="text-base leading-loose">
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Mayra Garza</strong>, Bellflower Unified School Board President<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Renita Armstrong</strong>, Bellflower Unified School Boardmember<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Jim Moreno</strong>, Coast Community College District Trustee<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Lorraine Prinsky</strong>, Coast Community College District Trustee<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Lamiya Hoque</strong>, Centralia School Boardmember<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Luis Flores</strong>, Centralia School Boardmember<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Phu Nguyen</strong>, Fountain Valley School District Trustee<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Ruthi Hanchett</strong>, Fullerton School Board Member<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Walter Muneton</strong>, Garden Grove Unified School Board President<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Barbara Dunsheath</strong>, North Orange County Community College District Trustee<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Tina Arias Miller</strong>, Rancho Santiago Community College District Trustee<br /></ScrollAnimation>
            </p>
        </div>
      </section>
      <section className="mx-auto my-8 py-10 px-4 lg:px-36 text-gray-700 text-center">
        <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
          <p className="text-sm text-center text-gray-600 mt-28 leading-loose">
          *Titles for education purposes only
          </p>
        </ScrollAnimation>
      </section>
    </Layout>
  )
}

export default Endorsements

export const Head = ({location}) => <SEO title="Endorsements" pathname={location.pathname} />